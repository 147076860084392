import { defineMessages } from 'react-intl';
import { OperationalCondition } from '@cooltra/api';

export default defineMessages<OperationalCondition>({
  OPERATIONAL: {
    defaultMessage: 'Operational',
  },
  BLOCKED: {
    defaultMessage: 'Blocked',
  },
  OFFLINE: {
    defaultMessage: 'Offline',
  },
  BATTERY_DISCHARGED: {
    defaultMessage: 'Battery discharged',
  },
  NOT_LOCKED: {
    defaultMessage: 'Not locked',
  },
  BATTERY_UNLOCKED: {
    defaultMessage: 'Battery unlocked',
  },
  IN_LOCATION: {
    defaultMessage: 'In a location',
  },
  WITH_OPERATOR: {
    defaultMessage: 'Taken by operator',
  },
  WORK_IN_PROGRESS: {
    defaultMessage: 'Work in progress',
  },
  RESERVED: {
    defaultMessage: 'Reserved',
  },
  RENTED: {
    defaultMessage: 'Rented',
  },
  AVAILABLE: {
    defaultMessage: 'Available',
  },
});
