import { useIntl } from 'react-intl';
import { classNames } from '@cooltra/utils';
import { Button } from '@cooltra/ui';
import { PropsWithChildren } from 'react';
import { Geoposition, useTakeVehicleMutation } from '@cooltra/api';
import { AxiosError } from 'axios';

import { useNotification } from '~/libs/notifications';
import { useVehiclePolling } from '~/libs/polling-data';
import { useCurrentPosition } from '~/libs/current-position';

import { getErrorMessageForTakingVehicle } from './errors';
import messages from './messages';

type TakeActionProps = PropsWithChildren & {
  className?: string;
  reason: string;
  vehicleId: string;
  onClose: () => void;
  disabled?: boolean;
};

export const TakeReason = ({
  children,
  className,
  reason,
  vehicleId,
  onClose,
  disabled,
}: TakeActionProps) => {
  const { formatMessage } = useIntl();
  const { addErrorNotification, addSuccessNotification } = useNotification();
  const { activatePollingVehicle } = useVehiclePolling();
  const currentPosition = useCurrentPosition();

  const geoposition = currentPosition.length
    ? ([currentPosition[1], currentPosition[0]] as Geoposition)
    : undefined;

  const { isPending, mutate } = useTakeVehicleMutation(vehicleId, geoposition, {
    onSuccess: () => {
      addSuccessNotification(formatMessage(messages.successfullyTaken));
      activatePollingVehicle(vehicleId);
      onClose();
    },
    onError: (error: AxiosError) => {
      const errorMessageFromStatusCode = getErrorMessageForTakingVehicle(
        error.response
      );

      addErrorNotification(
        errorMessageFromStatusCode.avoidTranslation
          ? errorMessageFromStatusCode.defaultMessage
          : formatMessage(errorMessageFromStatusCode)
      );
    },
  });

  const takeVehicle = () =>
    mutate({
      reason: reason,
    });

  return (
    <Button
      className={classNames('rounded-full', className)}
      loading={isPending}
      disabled={isPending || disabled}
      onClick={takeVehicle}
    >
      {children}
    </Button>
  );
};
