import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Modal, Textarea } from '@cooltra/ui';
import { useState } from 'react';
import { Geoposition, useTakeVehicleMutation } from '@cooltra/api';
import { AxiosError } from 'axios';

import { useNotification } from '~/libs/notifications';
import { useVehiclePolling } from '~/libs/polling-data';
import { getErrorMessageForTakingVehicle } from '~/common/FloatingMenu/ActionButtons/Take/errors';
import { useCurrentPosition } from '~/libs/current-position';

import messages from './messages';

export type TakeOtherReasonModalProps = {
  vehicleId: string;
  isOtherReasonModalOpen: boolean;
  onClose: () => void;
  onCancel: () => void;
};

export const TakeOtherReasonModal = ({
  vehicleId,
  isOtherReasonModalOpen,
  onClose,
  onCancel,
}: TakeOtherReasonModalProps) => {
  const { formatMessage } = useIntl();
  const [comment, setComment] = useState('');
  const currentPosition = useCurrentPosition();

  const geoposition = currentPosition.length
    ? ([currentPosition[1], currentPosition[0]] as Geoposition)
    : undefined;

  const handleClose = () => {
    onCancel();
    setComment('');
  };

  const { addErrorNotification, addSuccessNotification } = useNotification();
  const { activatePollingVehicle } = useVehiclePolling();

  const { isPending, mutate } = useTakeVehicleMutation(vehicleId, geoposition, {
    onSuccess: () => {
      addSuccessNotification(formatMessage(messages.successfullyTaken));
      activatePollingVehicle(vehicleId);
      onClose();
    },
    onError: (error: AxiosError) => {
      const errorMessageFromStatusCode = getErrorMessageForTakingVehicle(
        error.response
      );

      addErrorNotification(
        errorMessageFromStatusCode.avoidTranslation
          ? errorMessageFromStatusCode.defaultMessage
          : formatMessage(errorMessageFromStatusCode)
      );
    },
  });

  const takeVehicle = () =>
    mutate({
      reason: comment,
    });

  return (
    <Modal
      isOpen={isOtherReasonModalOpen}
      shouldReturnFocusAfterClose={false}
      hideHeader
    >
      <div className="p-6 w-80" data-testid="CLOSE_WORK_UNIT_MODAL">
        <h3 className="text-neutral-1000 font-semibold text-base text-center mb-4">
          <FormattedMessage {...messages.writeComment} />
        </h3>
        <Textarea
          rows={3}
          className="mb-3"
          disabled={false}
          placeholder={formatMessage(messages.writeCommentPlaceholders)}
          onChange={({ target }) => setComment(target.value)}
          value={comment}
        />
        <div className="flex flex-col gap-2.5">
          <Button
            loading={isPending}
            disabled={comment.length < 5}
            className="rounded-full"
            onClick={takeVehicle}
            emphasis="high"
          >
            <FormattedMessage {...messages.takeVehicle} />
          </Button>
          <Button
            loading={isPending}
            disabled={isPending}
            className="rounded-full"
            variant="danger"
            onClick={handleClose}
          >
            <FormattedMessage {...messages.cancel} />
          </Button>
        </div>
      </div>
    </Modal>
  );
};
