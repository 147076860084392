import { useVehicleStatusQuery, Vehicle } from '@cooltra/api';
import { getErrorStatus } from '@cooltra/axios';
import { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { TopNavigation, Screen, Error, FloatingMenu } from '~/common';

import {
  LastSync,
  CentralStand,
  Connection,
  Helmet,
  LockStatus,
  Power,
  TopCase,
  TopCaseLock,
  Saddle,
  Alarm,
  BatteryUnlocked,
} from './Items';
import messages from './messages';
import { NoDataAvailable } from './NoDataAvailable/NoDataAvailable';

export type BatteryProps = {
  onClose: () => void;
  vehicle: Vehicle;
};

const motorbikeInfo = [CentralStand, Helmet, LockStatus, Power, TopCase];
const niuMotorbikeInfo = [
  CentralStand,
  Helmet,
  LockStatus,
  Power,
  TopCase,
  TopCaseLock,
  Saddle,
  Alarm,
];
const segwayBikeInfo = [Power, LockStatus, BatteryUnlocked];
const bikeInfo = [Power, LockStatus];

export const Telematics = ({
  onClose,
  vehicle: { vehicleId, telematics },
}: BatteryProps) => {
  const navigate = useNavigate();

  const onBack = () => navigate(`/vehicles/${vehicleId}`);

  const {
    data: status,
    error,
    isError,
  } = useVehicleStatusQuery(vehicleId, {
    retry: false,
  });

  const renderContent = useCallback(() => {
    if (getErrorStatus(error) === 404) {
      return <NoDataAvailable />;
    }

    if (isError) {
      return <Error />;
    }

    if (!status) {
      return <></>;
    }

    const getInfo = () => {
      switch (telematics) {
        case 'SEGWAY':
          return segwayBikeInfo;
        case 'AXA':
          return bikeInfo;
        case 'NIU':
          return niuMotorbikeInfo;
        default:
          return motorbikeInfo;
      }
    };

    return (
      <>
        <ul data-testid="VEHICLE_TELEMATICS_LIST">
          {getInfo().map((TelematicsInfo, i) => (
            <li key={`telematics-info-${i}`}>
              <TelematicsInfo vehicleStatus={status} />
            </li>
          ))}
        </ul>
        <FloatingMenu
          vehicleId={vehicleId}
          onCloseWorkUnit={onClose}
          onStartWorkUnit={onClose}
        />
      </>
    );
  }, [error, isError, onClose, status, telematics, vehicleId]);

  return (
    <Screen
      data-testid="VEHICLE_TELEMATICS_SCREEN"
      header={
        <div className="bg-neutral-0 border-b border-neutral-100">
          <TopNavigation.Bar
            renderLeft={<TopNavigation.Back onClick={onBack} />}
            renderRight={<TopNavigation.Close onClick={onClose} />}
            title={
              <TopNavigation.Title>
                <FormattedMessage {...messages.title} />
              </TopNavigation.Title>
            }
          />
          {status && (
            <div className="mb-12">
              <div className="flex justify-center">
                <Connection vehicleStatus={status} />
              </div>
              <div className="mt-6">
                <LastSync vehicleStatus={status} telematics={telematics} />
              </div>
            </div>
          )}
        </div>
      }
      content={renderContent()}
    />
  );
};
