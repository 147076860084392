import { defineMessages } from 'react-intl';

export default defineMessages({
  filters: {
    defaultMessage: 'Filters',
  },
  assignee: {
    defaultMessage: 'Assignee',
  },
  operationalCondition: {
    defaultMessage: 'Operational condition',
  },
  battery: {
    defaultMessage: 'Battery level',
  },
  search: {
    defaultMessage: 'Search',
  },
  model: {
    defaultMessage: 'Models',
  },
  telematics: {
    defaultMessage: 'Telematics',
  },
  locations: {
    defaultMessage: 'Locations',
  },
  tags: {
    defaultMessage: 'Tags',
  },
  presets: {
    defaultMessage: 'Presets',
  },
  idleFrom: {
    defaultMessage: 'Without rentals since',
  },
});
