import { defineMessages } from 'react-intl';

export default defineMessages({
  batteryUnlocked: {
    defaultMessage: 'Battery unlocked',
  },
  locked: {
    defaultMessage: 'Locked',
  },
  unlocked: {
    defaultMessage: 'Unlocked',
  },
});
