import { TopLoadingBar } from '@cooltra/ui';
import { useEffect } from 'react';
import { IntlProvider } from 'react-intl';
import { ErrorBoundary, setUser } from '@sentry/react';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import { usePreferredLanguage } from '@cooltra/preferred-language';
import { useAuthClaimsQuery } from '@cooltra/auth-api';
import Div100vh from 'react-div-100vh';
import { useWakeLock } from 'react-screen-wake-lock';
import { useQuery } from '@tanstack/react-query';

import { getEnvVariable } from '~/utils/environment';
import { streetOperatorRoles } from '~/utils/operator-groups';
import { isValidModel } from '~/utils/devices';

import { NotAuthorized } from './NotAuthorized/NotAuthorized';
import { CrashPage } from './CrashPage/CrashPage';
import { InvalidDevice } from './InvalidDevice/InvalidDevice';
import { getDeviceModel } from './get-device-model';
import { Landing } from './Landing';

const Main = () => {
  const { language, messages } = usePreferredLanguage();
  const { data: claims, hasPermission } = useAuthClaimsQuery();
  const { request, isSupported } = useWakeLock();
  const { user } = useAuth0();

  useEffect(() => {
    if (isSupported) {
      request();
    }
  }, [isSupported, request]);

  useEffect(() => {
    if (user?.sub) {
      setUser({ id: user.sub });
    }
  }, [user?.sub]);

  const isLoading = !language || !claims;

  const isFromParis =
    claims?.systems.includes('Paris') && claims.systems.length === 1;

  const isStreetOperator = streetOperatorRoles.some(
    (role) => claims?.roles.includes(role)
  );

  const isStreetOperatorInParis =
    getEnvVariable('ENVIRONMENT') === 'production' &&
    isFromParis &&
    isStreetOperator;

  const deviceCheckQuery = useQuery({
    queryKey: ['device-check'],
    queryFn: () => getDeviceModel(user),
    enabled: isStreetOperatorInParis,
  });

  const isInvalidDevice =
    isStreetOperatorInParis &&
    deviceCheckQuery.isSuccess &&
    !isValidModel(deviceCheckQuery.data);

  if (isLoading) {
    return <TopLoadingBar />;
  }

  return (
    <IntlProvider locale={language} messages={messages}>
      <Div100vh className="relative overflow-hidden">
        <ErrorBoundary fallback={<CrashPage />}>
          {isInvalidDevice ? (
            <InvalidDevice device={deviceCheckQuery.data} />
          ) : !hasPermission('view:ops_app') ? (
            <NotAuthorized />
          ) : (
            <Landing />
          )}
        </ErrorBoundary>
      </Div100vh>
    </IntlProvider>
  );
};

export default withAuthenticationRequired(Main, {
  onRedirecting: () => <TopLoadingBar />,
});
